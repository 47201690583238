import React from "react";
import ReactDOM from "react-dom";
import FuzzySearch from "fuzzy-search";
import axios from "axios";

const Searchbar = () => {
  const [value, updateValue] = React.useState("");
  const [results, updateResults] = React.useState({
    categories: [],
    products: [],
  });
  const [isLoadingResults, setIsLoadingResults] = React.useState(false);
  const [hasFocus, setHasFocus] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".searchbar")) {
        setHasFocus(false);
      }
    });
  }, []);

  const handleOnChange = (e) => {
    const _value = e.target.value;
    updateValue(_value);
    if (_value.length > 2) {
      setIsLoadingResults(true);

      const formData = new FormData();

      formData.append("action", "search_product");
      formData.append("s", _value);

      axios.post(woocommerce_params.ajax_url, formData).then((res) => {
        updateResults((state) => ({
          ...res.data,
        }));
        setIsLoadingResults(false);
      });
    }
  };

  return (
    <>
      <form className="searchbarInput" action="/">
        <input
          value={value}
          type="search"
          name="s"
          placeholder="Zoeken"
          onChange={handleOnChange}
          onClick={() => setHasFocus(true)}
        />
        <button type="submit" className="btn">
          <img src={require("../images/search.svg")} alt="" />
        </button>
      </form>
      {hasFocus && value.length > 0 && (
        <div className="searchbarResults">
          {results.categories.length === 0 && (
            <a className="--empty" href={`/?s=${value}`}>
              Zoek naar "{value}"
            </a>
          )}
          {isLoadingResults ? (
            <div className="--products --loading">
              <span className="loader" />
            </div>
          ) : (
            <>
              {results.categories.length > 0 && (
                <ul className="--categories">
                  {results.categories.map((category, index) => (
                    <li key={index}>
                      <a href={category.url}>{category.name}</a>
                    </li>
                  ))}
                </ul>
              )}
              {results.products.length > 0 && (
                <ul className="--products">
                  {results.products.map((product) => (
                    <li key={product.name}>
                      <a href={product.url}>
                        <span
                          className="image"
                          dangerouslySetInnerHTML={{ __html: product.image }}
                        />
                        <span className="name">{product.name}</span>
                        <div>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: product.price_html,
                            }}
                          />
                          {product.is_on_sale && (
                            <span className="tag">Sale</span>
                          )}
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

const el = document.getElementById("searchbar");
if (el) {
  ReactDOM.render(<Searchbar />, el);
}
