import React from "react";
import ReactDOM from "react-dom";
import { useDrag } from "react-use-gesture";
import { useSpring, animated, config } from "react-spring";
import clamp from "lodash-es/clamp";
import "../scss/productgallery";

const el = document.getElementById("product-gallery");

const ProductGallery = ({ images }) => {
  const width = el.getBoundingClientRect().width;
  const height = width * 0.75;
  const index = React.useRef(0);
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [{ x }, set] = useSpring(() => ({ x: index.current * -1 * width }));
  const thumbsRef = React.useRef(null);

  // const bind = useDrag(({ last, movement: [mx], distance, down, velocity }) => {
  //   if (last && (distance > width / 2 || velocity > 1.5)) {
  //     index.current = clamp(
  //       index.current + (mx > 0 ? -1 : 1),
  //       0,
  //       images.length - 1
  //     );
  //   }

  //   set((prev) => {
  //     return {
  //       x: index.current * -1 * width + (down ? mx : 0),
  //       config: last ? config.default : config.stiff,
  //     };
  //   });

  //   setActiveSlide(index.current);
  // });

  const goTo = (i) => {
    index.current = i;
    set((prev) => ({ x: index.current * -1 * width }));
    setActiveSlide(index.current);
  };

  React.useEffect(() => {
    if (thumbsRef.current === null) return;

    const thumb = Array.from(thumbsRef.current.querySelectorAll("div"))[
      activeSlide
    ];

    const left =
      thumb.offsetLeft - width / 2 + thumb.getBoundingClientRect().width / 2;

    thumbsRef.current.scrollTo({ left, behavior: "smooth" });
  }, [activeSlide]);

  return (
    <>
      <div className="gallery__slider" style={{ width: width }}>
        <animated.div
          /*
          * 
          * {...bind()}
          */
          className="gallery__imageList"
          style={{
            width: images.length * width,
            transform: x.interpolate((x) => `translate3d(${x}px, 0px, 0px)`),
          }}
        >
          {images.map((image, index) => (
            <div style={{ width: width }} key={index}>
              <img src={image.medium[0]} style={{ width, height }} />
            </div>
          ))}
        </animated.div>
      </div>
      <div
        className="gallery__thumbs"
        style={{
          width: width,
        }}
        ref={thumbsRef}
      >
        {images.map((image, index) => (
          <div
            style={{
              opacity: activeSlide === index ? 1 : 0.5,
              backgroundImage: `url(${image.thumb[0]})`,
            }}
            onClick={() => goTo(index)}
            key={index}
          />
        ))}
      </div>
    </>
  );
};

if (el !== null) {
  ReactDOM.render(
    <ProductGallery images={JSON.parse(el.dataset.images)} />,
    el
  );
}
