import "../scss/style.scss";

import axios from "axios";
import "./Searchbar";
import "./InstagramFeed";
import "./ProductGallery";

document.addEventListener("click", (e) => {
  const el = e.target.closest("button.--favorite");
  if (el) {
    const productId = parseInt(el.dataset.productId);
    const isFavorited = el.dataset.favorite === "true";

    if (!document.body.classList.contains("logged-in")) {
      window.location = `${
        window.location.origin
      }/my-account?favorite=${productId}&return_url=${encodeURI(
        window.location.href + window.location.search
      )}`;
    }

    if (isFavorited) {
      // Update the ui before the action is confired
      el.setAttribute("data-favorite", "false");

      const favoritesList = document.querySelector(".products.--favorites");
      if (favoritesList) {
        el.parentNode.style.opacity = 0.6;
        el.parentNode.style.pointerEvents = "none";
        el.parentNode.style.cursor = "wait";
      }

      // If there is an error return the ui the the original state
      removeFavorite(productId)
        .then(() => {
          if (favoritesList) {
            el.parentNode.remove();
            if (Array.from(favoritesList.querySelectorAll("li")).length === 0) {
              document.querySelector(".no-orders").style.display = "flex";
            }
          }
        })
        .catch(() => {
          el.setAttribute("data-favorite", "true");
          if (favoritesList) {
            el.parentNode.style.opacity = 1;
            el.parentNode.style.pointerEvents = "initial";
            el.parentNode.style.cursor = "default";
          }
        });
    } else {
      // Update the ui before the action is confired
      el.setAttribute("data-favorite", "true");

      // If there is an error return the ui the the original state

      addFavorite(productId).catch(() => {
        el.setAttribute("data-favorite", "false");
      });
    }
  }
});

const addFavorite = (productId) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    formData.append("action", "add_product_to_favorites");
    formData.append("product_id", productId);

    axios
      .post(woocommerce_params.ajax_url, formData)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const removeFavorite = (productId) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    formData.append("action", "remove_product_from_favorites");
    formData.append("product_id", productId);

    axios
      .post(woocommerce_params.ajax_url, formData)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

document.addEventListener("click", function (e) {
  const el = e.target.closest("button[data-tooltip]");
  if (el) {
    const tooltip = el.querySelector(".tooltip");
    if (tooltip) {
      if (tooltip.classList.contains("active")) {
        tooltip.classList.remove("active");
      } else {
        tooltip.classList.add("active");
      }
    } else {
      el.insertAdjacentHTML(
        "beforeend",
        `<span class="tooltip">${el.dataset.tooltip}</span>`
      );
      el.querySelector(".tooltip").classList.add("active");
    }
  } else {
    Array.from(document.querySelectorAll(".tooltip")).forEach((el) =>
      el.classList.remove("active")
    );
  }
});

const menuBtn = document.querySelector(".btn.--menu");
if (menuBtn) {
  menuBtn.addEventListener("click", (e) => {
    const target = document.querySelector(".nav--primary");
    if (target.classList.contains("menu-active")) {
      target.classList.remove("menu-active");
    } else {
      target.classList.add("menu-active");
    }
  });
}

const filterBtn = document.querySelector(".btn.--filter");
if (filterBtn) {
  filterBtn.addEventListener("click", function (e) {
    document.body.classList.add("filter-active");
  });

  document.addEventListener("click", function (e) {
    const filter = e.target.closest(".product-filter, .btn.--filter");
    if (!filter && document.body.classList.contains("filter-active")) {
      document.body.classList.remove("filter-active");
    }
  });

  document
    .querySelector(".btn.--apply-filters")
    .addEventListener("click", function (e) {
      document.body.classList.remove("filter-active");
    });

    document
    .querySelector(".filter--close")
    .addEventListener("click", function (e) {
      document.body.classList.remove("filter-active");
    });
}

document.querySelectorAll("[data-toggle]").forEach((el) => {
  el.addEventListener("click", (e) => {
    e.stopPropagation();

    const id = el.dataset.toggle;
    const target = document.querySelector(id);
    if (target !== null) {
      target.classList.toggle("active");

      document.body.addEventListener("click", (e) => {
        if (!e.target.closest(id)) {
          target.classList.remove("active");
        }
      });
    }
  });
});

document.querySelectorAll("[data-open]").forEach((el) => {
  el.addEventListener("click", (e) => {
    e.stopPropagation();

    const id = el.dataset.open;
    const target = document.querySelector(id);
    if (target !== null && !target.classList.contains("active")) {
      target.classList.add("active");

      document.body.addEventListener("click", (e) => {
        if (!e.target.closest(id)) {
          target.classList.remove("active");
        }
      });
    }
  });
});

document.querySelectorAll("[data-close]").forEach((el) => {
  el.addEventListener("click", (e) => {
    e.preventDefault();

    const id = el.dataset.close;
    const target = document.querySelector(id);
    if (target !== null && target.classList.contains("active")) {
      target.classList.remove("active");
    }
  });
});

var timeout;

jQuery(function ($) {
  $(".woocommerce").on("change", "input.qty", function () {
    if (timeout !== undefined) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(function () {
      $("[name='update_cart']").prop("disabled", false);
      $("[name='update_cart']").trigger("click");
    }, 1000); // 1 second delay, half a second (500) seems comfortable too
  });
});

var mySwiper = new Swiper(".homeProducts .swiper-container", {
  // Navigation arrows
  slidesPerView: 4,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    480: {
      slidesPerView: 1.3,
    },
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 4,
    },
  },
});
