import React from "react";
import ReactDOM from "react-dom";
import "../scss/instagram";

const transformInstagramJson = (data) => {
  return {
    images: data.graphql.user.edge_owner_to_timeline_media.edges.map(
      (edge) => ({
        id: edge.node.id,
        src: edge.node.thumbnail_src,
        caption: edge.node.edge_media_to_caption.edges[0].node.text,
        isVideo: edge.node.is_video,
        link: `https://www.instagram.com/p/${edge.node.shortcode}`,
      })
    ),
  };
};

const InstagramFeed = ({ username }) => {
  const [images, updateImages] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(true);

  React.useEffect(() => {
    fetch(`https://www.instagram.com/${username}/?__a=1`, {
      mode: 'no-cors'
    })
      .then((res) => res.json())
      .then((res) => {
        const data = transformInstagramJson(res);
        console.log(res);
        updateImages(data.images.slice(0, 60));
        setIsFetching(false);
      })
      .catch((err) => console.error(err));
  }, []);

  if (isFetching) return null;

  return (
    <ul>
      {images.map((image, index) => (
        <li key={image.id}>
          <a href={image.link} title={image.caption} target="_blank">
            <img src={image.src} />
          </a>
          <div className="caption">{image.caption}</div>
        </li>
      ))}
    </ul>
  );
};

const el = document.getElementById("instagram-feed");

if (el !== null) {
  ReactDOM.render(<InstagramFeed username={el.dataset.username} />, el);
}